<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row v-if="!extData.id">
              <b-col cols="5">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="1.Đợt tuyển sinh:"
                      label-for="admissionRoundSelected"
                    >
                      <v-select
                        v-model="admissionRoundSelected"
                        :options="admissionRounds"
                        :reduce="option => option.value"
                        :disabled="!!extData.id"
                        :clearable="false"
                        placeholder="1.Đợt tuyển sinh"
                        @input="onChangeAdmissionRound"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="2.Phương thức tuyển sinh:"
                      label-for="admissionFormSelected"
                    >
                      <v-select
                        v-model="admissionFormSelected"
                        :options="dataListAdmissionForm"
                        :reduce="option => option.value"
                        :disabled="!!extData.id"
                        :clearable="false"
                        placeholder="2.Phương thức tuyển sinh"
                        @input="onChangeAdmissionForm"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="7">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label-cols="3"
                      label-cols-lg="2"
                      label="3.Môn thi:"
                      label-for="admissionSubjectSelected"
                    >
                      <v-select
                        v-model="admissionSubjectSelected"
                        :options="admissionSubjectOptions"
                        :reduce="option => option.value"
                        :disabled="!!extData.id"
                        :clearable="false"
                        placeholder="3.Chọn môn thi:"
                        @input="onChangeAdmissionSubject"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label-cols="3"
                      label-cols-lg="2"
                      label="4.Phòng thi:"
                      label-for="admissionExaminationRoomSubjectsSelected"
                    >
                      <v-select
                        v-model="admissionExaminationRoomSubjectsSelected"
                        :options="admissionExaminationRoomSubjectsOptions"
                        :reduce="option => option.value"
                        :disabled="!!extData.id"
                        :clearable="false"
                        placeholder="4.Chọn phòng thi"
                        @input="onChangeAdmissionExaminationRoomSubjects"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <div class="text-right">
                  <b-button
                    v-show="filter.admissionExaminationRoomId > 0 && !isCreateHeader && headerCodeCreatable && studentExaminationRooms.length>0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    style="margin-right: 5px"
                    @click="onCreateHeaderCode"
                  >
                    <span class="text-nowrap text-right">Tạo phách</span>
                  </b-button>
                  <b-button
                    v-show="filter.admissionExaminationRoomId > 0 && isCreateHeader && canRemixHeaderCode && studentExaminationRooms.length>0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    style="margin-right: 5px"
                    @click="onCreateHeaderCodeAgain"
                  >
                    <span class="text-nowrap text-right">Tạo lại phách</span>
                  </b-button>
                  <b-button
                    v-show="filter.admissionExaminationRoomId > 0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="info"
                    style="margin-right: 5px"
                    @click="onSelectApplicantStudentExaminationRoom"
                  >
                    <span class="text-nowrap text-right">Xem danh sách</span>
                  </b-button>
                  <b-button
                    v-show="selectedRowTable.length > 0 && (changeExaminationRoomUpdatable || supervisorExaminationRoomUpdatable)"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    style="margin-right: 5px"
                    @click="onChangeExaminationRom"
                  >
                    <span class="text-nowrap text-right">Chuyển phòng thi</span>
                  </b-button>
                  <b-dropdown
                    v-show="filter.admissionExaminationRoomId > 0 && changeExaminationRoomPrintable && studentExaminationRooms.length>0"
                    style="margin-right: 5px;"
                    right
                    variant="success"
                    icon="InfoIcon"
                    text="Xuất pdf"
                  >
                    <b-dropdown-item
                      variant="primary"
                      class="btn-icon"
                      size="sm"
                      @click="downloadPdf"
                    >
                      Danh sách phách thí sinh
                    </b-dropdown-item>
                    <b-dropdown-item
                      variant="primary"
                      class="btn-icon"
                      size="sm"
                      @click="downloadPdf_DiemPhach"
                    >
                      Danh sách phách vào điểm thí sinh
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-dropdown
                    v-show="filter.admissionExaminationRoomId > 0 && changeExaminationRoomPrintable && studentExaminationRooms.length>0"
                    right
                    variant="success"
                    icon="InfoIcon"
                    text="Xuất excel"
                  >
                    <b-dropdown-item
                      variant="primary"
                      class="btn-icon"
                      size="sm"
                      @click="exportExcel"
                    >
                      Danh sách phách thí sinh
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  style-class="my-table vgt-table striped bordered"
                  :columns="columns"
                  :rows="studentExaminationRooms"
                  :line-numbers="true"
                  :pagination-options="paginationOptions"
                  :select-options="{
                    enabled: true,
                    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                    selectionInfoClass: 'custom-class',
                    selectionText: 'rows selected',
                    clearSelectionText: 'clear',
                    disableSelectInfo: true, // disable the select info panel on top
                    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                  }"
                  @on-selected-rows-change="selectionChanged($event)"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'stt'">
                      {{ props.row.originalIndex + 1 }}
                    </span>
                    <span v-else-if="props.column.field === 'headerCode'">
                      {{ (headerCodeCreatable || canRemixHeaderCode) ? props.row.headerCode : '---' }}
                    </span>
                    <span v-else-if="props.column.field === 'action'">
                      <b-button-group>
                        <b-button
                          v-show="props.row.status >= 0"
                          v-b-modal.revenueSaveModal
                          variant="warning"
                          class="btn-icon"
                          size="sm"
                          title="Xử lý vi phạm"
                          @click="onDeducted(props.row)"
                        >
                          <feather-icon icon="AlertTriangleIcon" />
                        </b-button>
                        <b-dropdown
                          v-show="(props.row.status >= 0 && !props.row.headerCode)"
                          right
                          variant="danger"
                          no-caret
                          size="sm"
                        >
                          <template #button-content>
                            <feather-icon
                              icon="MoreHorizontalIcon"
                              size="16"
                              class="align-middle text-body"
                            />
                          </template>
                          <b-dropdown-item
                            v-b-modal.revenueSaveModal
                            variant="warning"
                            class="btn-icon"
                            size="sm"
                            title="Xử lý vắng thi"
                            @click="onMarkAbsent(props.row)"
                          >Xử lý vắng thi
                          </b-dropdown-item>
                          <b-dropdown-item
                            variant="danger"
                            class="btn-icon"
                            size="sm"
                            title="Hủy bài thi"
                            @click="onBan(props.row)"
                          >
                            Hủy bài thi
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-button-group>
                    </span>
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <b-modal
      id="admissionExaminationRoomModal"
      ref="admissionExaminationRoomModal"
      size="lg"
      title="Danh sách phòng thi"
    >
      <v-select
        v-model="room_id_change"
        :options="roomOptions"
        :reduce="option => option.value"
      />
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            @click="onSave()"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('admissionExaminationRoomModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
    <validation-observer
      ref="deductedFormRef"
      #default="{invalid}"
    >
      <b-modal
        id="deductedModal"
        ref="deductedModal"
        size="lg"
        title="Xử lý trường hợp vi phạm"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label-for="absentType"
            >
              <template v-slot:label>
                Mức xử lý <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mức xử lý"
                rules="required"
              >
                <v-select
                  v-model="deductedValue"
                  :options="deductLevels"
                  :reduce="option => option.value"
                  @input="onchangeDeductLevel"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label-cols="3"
              label-cols-lg="3"
            >
              <template v-slot:label>
                Lý do vi phạm <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Lý do vi phạm"
                rules="required"
              >
                <b-form-input
                  v-model="deductedReason"
                  type="text"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSaveDeducted()"
            >
              <span class="text-right">
                <feather-icon icon="CheckIcon" /> Lưu
              </span>
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$bvModal.hide('deductedModal')"
            >
              <span class="text-right">
                <feather-icon icon="XIcon" /> Hủy
              </span>
            </b-button>
          </div>
        </template>
      </b-modal>
    </validation-observer>

    <validation-observer
      ref="creditClassSaveFormRef"
      #default="{invalid}"
    >
      <b-modal
        id="absentModal"
        ref="absentModal"
        size="lg"
        title="Xử lý trường hợp vắng thi"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label-for="absentType"
            >
              <template v-slot:label>
                Hình thức xử lý <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Hình thức xử lý"
                rules="required"
              >
                <v-select
                  v-model="absentForm.absentType"
                  :options="absentTypes"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="Lý do vắng:"
            >
              <b-form-input
                v-model="absentForm.note"
                type="text"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSaveAbsent()"
            >
              <span class="text-right">
                <feather-icon icon="CheckIcon" /> Lưu
              </span>
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$bvModal.hide('absentModal')"
            >
              <span class="text-right">
                <feather-icon icon="XIcon" /> Hủy
              </span>
            </b-button>
          </div>
        </template>
      </b-modal>
    </validation-observer>
    <vue-html2pdf
      ref="html2Pdf"
      :show-layout="controlValue.showLayout"
      :float-layout="controlValue.floatLayout"
      :enable-download="controlValue.enableDownload"
      :preview-modal="controlValue.previewModal"
      :filename="controlValue.filename"
      :paginate-elements-by-height="controlValue.paginateElementsByHeight"
      :pdf-quality="controlValue.pdfQuality"
      :pdf-format="controlValue.pdfFormat"
      :pdf-orientation="controlValue.pdfOrientation"
      :pdf-content-width="controlValue.pdfContentWidth"
      :manual-pagination="controlValue.manualPagination"
      :html-to-pdf-options="htmlToPdfOptions"
      @hasDownloaded="hasDownloaded($event)"
    >
      <pdf-content
        slot="pdf-content"
        :content-pdf="contentPdf"
        :list-student-examination-room="this.studentExaminationRooms"
      />
    </vue-html2pdf>
  </div>
</template>

<script>
import {
  BButtonGroup,
  BButton,
  BCard,
  BCol,
  BContainer,
  BFormCheckbox,
  BFormGroup,
  BOverlay,
  BRow,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import VueHtml2pdf from 'vue-html2pdf'
import PdfContent from '@/views/student-examination-room/PdfContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { getUser } from '@/auth/utils'
import { ABSENT_TYPES } from '@/const/status'
export default {
  name: 'StudentExaminationRoom',
  directives: {
    Ripple,
  },
  components: {
    PdfContent,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BCard,
    BCol,
    BContainer,
    BFormInput,
    BOverlay,
    BRow,
    VueGoodTable,
    BFormInvalidFeedback,
    vSelect,
    VueHtml2pdf,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    extData: {
      type: Object,
      default: () => ({
        id: null,
        admissionSubjectId: null,
      }),
    },
  },
  data() {
    return {
      user: getUser(),
      required,
      admissionRoundSelected: undefined,
      admissionFormSelected: undefined,
      admissionExaminationRoomSubjectsSelected: undefined,
      admissionSubjectSelected: undefined,
      subjectName: undefined,
      roomName: undefined,
      isLoading: false,
      room_id_change: undefined,
      isCreateHeader: false,
      selectedRowTable: [],
      deductedValue: 0,
      deductedReason: null,
      applicantStudentExaminationRoomId: 0,
      absentForm: { id: null, absentType: null, note: '' },
      filter: {
        admissionExaminationRoomId: null,
      },
      contentRendered: false,
      generatingPdf: false,
      pdfDownloaded: false,
      controlValue: {
        showLayout: false,
        floatLayout: true,
        enableDownload: true,
        previewModal: true,
        paginateElementsByHeight: 1100,
        manualPagination: false,
        filename: 'Danhsachphach',
        pdfQuality: 2,
        pdfFormat: 'a4',
        pdfOrientation: 'portrait',
        pdfContentWidth: '800px',
      },
      contentPdf: {
        lop: '',
        namHoc: '',
        hocKy: '',
        phongThi: '',
        monHoc: '',
        lanThi: 1,
      },
      paginationOptions: {
        enabled: false,
      },
      columns: [
        {
          label: 'CCCD',
          field: 'identification',
          sortable: false,
          width: '10%',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Tên',
          field: 'name',
          sortable: false,
          width: '15%',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Ngày sinh',
          field: 'dob',
          sortable: false,
          width: '12%',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'SBD',
          field: 'bibNumber',
          sortable: false,
          width: '10%',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Môn thi',
          field: 'admissionSubjectName',
          sortable: false,
          width: '10%',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Số phách',
          field: 'headerCode',
          width: '10%',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Thao tác',
          field: 'action',
          width: '15%',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      studentExaminationRoomsByHeaderCode: [],
      absentTypes: ABSENT_TYPES,
    }
  },
  computed: {
    ...mapGetters({
      studentExaminationRooms: 'applicantStudentExaminationRoom/studentExaminationRooms',
      examinationRoomSubjects: 'applicantStudentExaminationRoom/examinationRoomSubjects',
      admissionRounds: 'admissionRound/admissionRoundsByOrganization',
      dataListAdmissionForm: 'admissionExaminationRoom/admissionForms',
      listAdmissionSubject: 'admissionExaminationRoom/listAdmissionSubject',
      title: 'applicantStudentExaminationRoom/title',
      // absentTypes: 'studentExaminationRoom/absentTypes',
      deductLevels: 'dropdown/admissionDeductLevels',
    }),
    admissionSubjectOptions() {
      return this.listAdmissionSubject.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
    htmlToPdfOptions() {
      return {
        margin: 0,
        filename: 'Danhsachphach.pdf',
        image: {
          type: 'jpeg',
          quality: 0.98,
        },
        enableLinks: true,
        html2canvas: {
          scale: this.controlValue.pdfQuality,
          useCORS: true,
        },
        jsPDF: {
          unit: 'in',
          format: this.controlValue.pdfFormat,
          orientation: this.controlValue.pdfOrientation,
        },
      }
    },
    admissionExaminationRoomSubjectsOptions() {
      return this.examinationRoomSubjects.map(item => ({ value: item.id, label: item.name }))
    },
    roomOptions() {
      return this.examinationRoomSubjects.map(item => ({ value: item.id, label: `${item.name}` }))
    },
    headerCodeCreatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.HEADER_CODE_EXAM)
    },
    canRemixHeaderCode() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.REMIX_EXAMINATION_HEADER)
    },
    changeExaminationRoomUpdatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.APPLICANT_STUDENT_EXAMINATION_ROOM)
    },
    supervisorExaminationRoomUpdatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.SUPERVISOR_ADMISSION_EXAMINATION_ROOM)
    },
    changeExaminationRoomPrintable() {
      return hasPermissionForResource(PermissionCode.PRINT, ResourceCode.APPLICANT_STUDENT_EXAMINATION_ROOM)
    },
  },
  async created() {
    this.filter.admissionExaminationRoomId = this.extData ? this.extData.id : null
    this.isLoading = true
    try {
      if (this.filter.admissionExaminationRoomId) {
        await Promise.all([
          this.getTitleValue(),
          this.getStudentExaminationRooms(this.filter)
        ])
        this.checkIsCreateHeader()
      } else {
        await this.getAdmissionRoundsByOrganization({ organizationId: getUser().orgId })
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getStudentExaminationRooms: 'applicantStudentExaminationRoom/getStudentExaminationRooms',
      readAdmissionFormByAdmissionRoundId: 'admissionExaminationRoom/readAdmissionFormByAdmissionRoundId',
      getAdmissionRoundsByOrganization: 'admissionRound/readAdmissionRoundsByOrganization',
      getAdmissionSubject: 'admissionExaminationRoom/getAdmissionSubject',
      getExaminationRoomSubjects: 'applicantStudentExaminationRoom/getExaminationRoomSubjects',
      changeExaminationRooms: 'applicantStudentExaminationRoom/changeExaminationRooms',
      getTitle: 'applicantStudentExaminationRoom/getTitle',
      createHeaderStudentExaminationRoom: 'admissionExaminationRoom/createHeaderStudentExaminationRoom',
      createHeaderStudentExaminationRoomAgain: 'admissionExaminationRoom/createHeaderStudentExaminationRoomAgain',
      banStudent: 'applicantStudentExaminationRoom/banStudentExaminationRooms',
      deductedStudent: 'applicantStudentExaminationRoom/deductedStudentExaminationRooms',
      saveAbsent: 'applicantStudentExaminationRoom/saveAbsent',
      getDeductLevels: 'dropdown/getAdmissionDeductLevels',
      exportPdfScoreHeaderCode: 'applicantStudentExaminationRoom/exportPdfScoreHeaderCode',
      exportPdfDanhSachSinhVien: 'applicantStudentExaminationRoom/exportPdfDanhSachSinhVien',
    }),

    // checkShowViPham() {
    //   return this.filter.admissionExaminationRoomId > 0 && this.isCreateHeader && this.canRemixHeaderCode && this.studentExaminationRooms.length>0
    // },

    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onChangeAdmissionRound(id) {
      if (this.admissionRoundSelected == null) {
        this.reset()
        this.$store.commit('admissionExaminationRoom/SET_ADMISSION_FORMS', { data: [] })
        this.$store.commit('applicantStudentExaminationRoom/SET_APPLICANT_STUDENT_EXAMINATION_ROOM_SUBJECTS', { data: [] })
        this.$store.commit('admissionExaminationRoom/SET_ADMISSION_SUBJECT', { data: [] })
        this.admissionFormSelected = null
        this.admissionExaminationRoomSubjectsSelected = null
        this.admissionSubjectSelected = null
      } else {
        await this.readAdmissionFormByAdmissionRoundId({ admissionRoundId: id })
      }
    },
    async onChangeAdmissionForm() {
      if (this.admissionFormSelected == null) {
        this.reset()
        this.$store.commit('admissionExaminationRoom/SET_ADMISSION_SUBJECT', { data: [] })
        this.$store.commit('applicantStudentExaminationRoom/SET_APPLICANT_STUDENT_EXAMINATION_ROOM_SUBJECTS', { data: [] })
        this.admissionExaminationRoomSubjectsSelected = null
        this.admissionSubjectSelected = null
      } else {
        await this.getAdmissionSubject({
          admissionRoundId: this.admissionRoundSelected,
          admissionFormId: this.admissionFormSelected,
        })
      }
    },
    onChangeAdmissionSubject() {
      if (this.admissionSubjectSelected == null) {
        this.reset()
        this.$store.commit('applicantStudentExaminationRoom/SET_APPLICANT_STUDENT_EXAMINATION_ROOM_SUBJECTS', { data: [] })
        this.admissionExaminationRoomSubjectsSelected = null
      } else {
        this.admissionExaminationRoomSubjectsSelected = null
        this.reset()
        this.getExaminationRoomSubjects({
          admissionRoundId: this.admissionRoundSelected,
          admissionFormId: this.admissionFormSelected,
          admissionSubjectId: this.admissionSubjectSelected,
        })
      }
    },
    onChangeAdmissionExaminationRoomSubjects() {
      if (this.admissionExaminationRoomSubjectsSelected == null) {
        this.reset()
      } else {
        this.filter.admissionExaminationRoomId = this.admissionExaminationRoomSubjectsSelected
        this.getTitle({
          admissionExaminationRoomId: this.filter.admissionExaminationRoomId,
        })
      }
    },

    reset() {
      this.filter.admissionExaminationRoomId = 0
      this.$store.commit('applicantStudentExaminationRoom/SET_APPLICANT_STUDENT_EXAMINATION_ROOM_SUBJECTS', { data: [] })
    },

    async onCreateHeaderCode() {
      this.$swal({
        title: 'Bạn chắc chắn muốn tạo phách cho TẤT CẢ phòng thi của học phần này?',
        text: 'Hãy kiểm tra TẤT CẢ phòng đã kết thúc thi!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.createHeaderStudentExaminationRoom({
              admissionFormId: this.title[0].admissionFormId,
              admissionSubjectId: this.extData ? this.extData.admissionSubjectId : this.admissionSubjectSelected,
            })
            // @TODO: hien thong bao tao phach thanh cong
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })

                await this.onSelectApplicantStudentExaminationRoom()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
            // this.isCreateHeader = hasPermissionForResource(PermissionCode.CREATE, ResourceCode.REMIX_EXAMINATION_HEADER)
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },

    async onCreateHeaderCodeAgain() {
      this.$swal({
        title: 'Bạn chắc chắn muốn tạo lại phách cho TẤT CẢ phòng thi của học phần này?',
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.createHeaderStudentExaminationRoomAgain({
              admissionFormId: this.title[0].admissionFormId,
              admissionSubjectId: this.title[0].admissionSubjectId,
            })
            // @TODO: hien thong bao tao lai phach thanh cong
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                await this.onSelectApplicantStudentExaminationRoom()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },

    async getTitleValue() {
      await this.getTitle(this.filter)
      // this.contentPdf.heDaoTao = this.title[0].heDaoTao
      // this.contentPdf.phongThi = this.title[0].phongThi
      // this.contentPdf.hocKy = this.title[0].hocKy
      // this.contentPdf.monHoc = this.title[0].monHoc
      // this.contentPdf.namHoc = this.title[0].namHoc
    },

    checkIsCreateHeader() {
      if (this.studentExaminationRooms.length > 0) {
        const student = this.studentExaminationRooms[0]
        if (student.headerCode && student.headerCode.trim().length > 0) {
          this.isCreateHeader = true
          return this.isCreateHeader
        }
      }
      return false
    },

    async onSelectApplicantStudentExaminationRoom() {
      this.isLoading = true
      try {
        await this.getStudentExaminationRooms(this.filter)
        this.checkIsCreateHeader()
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },

    async onDeducted(item) {
      this.isLoading = true
      try {
        await this.getDeductLevels()
        this.applicantStudentExaminationRoomId = item.applicantStudentExaminationRoomId
        this.deductedReason = item.note
        this.deductedValue = item.percent_deducted
        this.$refs.deductedModal.show()
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },

    onMarkAbsent(item) {
      this.absentForm.id = item.applicantStudentExaminationRoomId
      this.absentForm.absentType = item.absentType
      this.absentForm.note = item.note ? item.note : ''
      this.$refs.absentModal.show()
    },

    onBan(item) {
      this.$swal({
        title: `Bạn chắc chắn muốn hủy bài thi sinh viên <span class="text-danger">${item.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.banStudent({ applicant_student_examination_room_id: item.applicantStudentExaminationRoomId })
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getStudentExaminationRooms(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },

    exportExcel() {
      this.isLoading = true
      if (this.studentExaminationRooms.length > 0) {
        try {
          this.studentExaminationRoomsByHeaderCode = [...this.studentExaminationRooms].sort((a, b) => a.headerCode - b.headerCode)
          const tHeader = ['Họ tên', 'Ngày sinh', 'Số CCCD', 'SBD', 'Số phách']
          const filterVal = ['name', 'dob', 'identification', 'bibNumber', 'headerCode']
          const dataJson = this.formatJson(filterVal, this.studentExaminationRoomsByHeaderCode)
          import('@/utils/Export2Excel').then(excel => {
            excel.export_json_to_excel({
              header: tHeader,
              data: dataJson,
              filename: 'Danhsachphach',
              autoWidth: true,
              bookType: 'xlsx',
            })
          })
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Chưa thực hiện lấy danh sách phách',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        this.isLoading = false
      }
    },

    async downloadPdf() {
      this.isLoading = true
      if (this.studentExaminationRooms.length > 0) {
        try {
          const data = await this.exportPdfDanhSachSinhVien({
            admissionExaminationRoomId: this.filter.admissionExaminationRoomId,
          })
          if (data) {
            const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
            window.open(url, '_blank')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'File không có dữ liệu',
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Chưa thực hiện lấy danh sách phách',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        this.isLoading = false
      }
    },

    compare(propName) {
      return function (a, b) {
        if (a[propName] < b[propName]) return -1
        if (a[propName] > b[propName]) return 1
        return 0
      }
    },

    async downloadPdf_DiemPhach() {
      this.isLoading = true
      try {
        const data = await this.exportPdfScoreHeaderCode({
          admissionExaminationRoomId: this.filter.admissionExaminationRoomId,
          score: 0, // Không hiện điểm
        })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },

    validateControlValue() {
      if (this.controlValue.pdfQuality > 2) {
        alert('pdf-quality value should only be 0 - 2')
        this.controlValue.pdfQuality = 2

        return false
      }

      if (!this.controlValue.paginateElementsByHeight) {
        alert('paginate-elements-by-height value cannot be empty')
        this.controlValue.paginateElementsByHeight = 1400

        return false
      }

      const paperSizes = [
        'a0',
        'a1',
        'a2',
        'a3',
        'a4',
        'letter',
        'legal',
        'a5',
        'a6',
        'a7',
        'a8',
        'a9',
        'a10',
      ]

      if (!paperSizes.includes(this.controlValue.pdfFormat)) {
        alert(`pdf-format value should only be ${paperSizes}`)
        this.controlValue.pdfFormat = 'a4'

        return false
      }

      if (!this.controlValue.pdfOrientation) {
        alert('pdf-orientation value cannot be empty')
        this.controlValue.pdfOrientation = 'portrait'

        return false
      }

      if (!this.controlValue.pdfContentWidth) {
        alert('pdf-content-width value cannot be empty')
        this.controlValue.pdfContentWidth = '800px'

        return false
      }

      return true
    },

    hasDownloaded(blobPdf) {
      this.pdfDownloaded = true
    },

    domRendered() {
      this.contentRendered = true
    },

    selectionChanged(event) {
      this.selectedRowTable = event.selectedRows
    },

    async onChangeExaminationRom() {
      if (this.selectedRowTable.length > 0) {
        await this.getExaminationRoomSubjects({
          admissionRoundId: this.title[0].admissionRoundId,
          admissionFormId: this.title[0].admissionFormId,
          admissionSubjectId: this.title[0].admissionSubjectId,
        })
        this.$refs.admissionExaminationRoomModal.show()
      }
    },

    async onSave() {
      try {
        if (this.room_id_change > 0) {
          await this.changeExaminationRooms({ admissionExaminationRoomId: this.room_id_change.toString(), listApplicantStudentId: this.selectedRowTable.map(item => (item.applicantStudentExaminationRoomId)).join(',') })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Chuyển danh sách phòng thi thành công',
              icon: 'XCircleIcon',
              variant: 'success',
            },
          })
          await this.getStudentExaminationRooms(this.filter)
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Chưa chọn phòng thi để chuyển!',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.$bvModal.hide('admissionExaminationRoomModal')
      }
    },
    onchangeDeductLevel() {
      this.deductedReason = ''
    },
    async onSaveDeducted() {
      const valid = this.$refs.deductedFormRef.validate()
      if (valid) {
        try {
          const deductId = this.deductLevels.find(e => e.value === this.deductedValue).id
          await this.deductedStudent({
            deductId,
            applicant_student_examination_room_id: this.applicantStudentExaminationRoomId,
            deducted_value: this.deductedValue,
            deducted_reason: this.deductedReason,
          })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thực hiện trừ điểm thành công!',
              icon: 'XCircleIcon',
              variant: 'success',
            },
          })
          await this.getStudentExaminationRooms(this.filter)
          this.$bvModal.hide('deductedModal')
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },

    async onSaveAbsent() {
      try {
        const response = await this.saveAbsent(this.absentForm)
        if (response) {
          const {
            isSuccessful,
            message,
          } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Xử lý vắng thi thành công!',
                icon: 'XCircleIcon',
                variant: 'success',
              },
            })
            this.$bvModal.hide('absentModal')
            await this.getStudentExaminationRooms(this.filter)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.absentForm = { id: null, absentType: null, note: '' }
        this.isLoading = false
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    // Call the API query method when the URL changes
    this.reset()
    next()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
